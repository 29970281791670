import { Preferences } from "@capacitor/preferences"

export async function localSet(where, what, ifObject){
    let data = ""
    if(ifObject === true){
        const objeto = JSON.stringify(what)
        await Preferences.set({
            key : where,
            value : objeto
        }).then(() => {
            data = true
        })
        .catch(() => {
            data = false
        })
    }else{
        await Preferences.set({
            key : where,
            value : what
        }).then(() => {
            data = true
        })
        .catch(() => {
            data = false
        })
    }
    return data

}

export async function localGet(where){
    let data = ""
    const localData = await Preferences.get({
        key : where
    })
    data = localData.value
    return data
}

export async function localDelete(where){
    let data = ""
    await Preferences.remove({
        key : where
    }).then(() => {
        data = true
    })
    .catch(() => {
        data = false
    })
    return data

}