import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

//Inicializamos firebase
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD17kpC15qPX4VNtXmeVSE5jaFkQ1mFexY",
  authDomain: "cardiosalusapp.firebaseapp.com",
  databaseURL: "https://cardiosalusapp-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "cardiosalusapp",
  storageBucket: "cardiosalusapp.appspot.com",
  messagingSenderId: "217301402264",
  appId: "1:217301402264:web:4f269ff2fc4714945f86ed"
};

// Initialize Firebase
initializeApp(firebaseConfig)

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

//animate css
import 'animate.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

const app = createApp(App)
  .use(IonicVue)
  .use(router);
  
router.isReady().then(() => {
  app.mount('#app');
});