import { getDatabase, ref, set, get, remove , child, onValue , update, onChildAdded, query, orderByChild, equalTo  } from "firebase/database"
import firebase from 'firebase/compat/app';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD17kpC15qPX4VNtXmeVSE5jaFkQ1mFexY",
  authDomain: "cardiosalusapp.firebaseapp.com",
  databaseURL: "https://cardiosalusapp-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "cardiosalusapp",
  storageBucket: "cardiosalusapp.appspot.com",
  messagingSenderId: "217301402264",
  appId: "1:217301402264:web:4f269ff2fc4714945f86ed"
};

// Initialize Firebase
initializeApp(firebaseConfig)

const db = getDatabase()

export async function baseConnect(where){
    let datos = ""
    const dbref = ref(db)
    
    await get(child(dbref, where))
        .then(data => {
            datos = data.val()
        })
    return datos
}

export async function baseConnectPut(where, post){
    let datos = ""
    await set(ref(db, where) , post)
        .then(() => {
            datos = true
        })
        .catch(err => {
            datos = false
        })
    return datos
}

export async function baseConnectDelete(where){
    await remove(ref(db, where))
    .then(() => {
        console.log("Borrado correctamente")
    })
    .catch(err => {
        console.log(err)
    })
}

export async function baseConnectUpdate(where, what){
    let datos = ""
    const starCountRef = await ref(db, where)
    onValue(starCountRef, async (data) => {
        datos = await data.val()
    })
    return datos
}

export async function baseConnectSearch(where, query1, what){
    let datareturn = ""
    const dbref = await query(ref(db, where), orderByChild(query1), equalTo(what))
    await get(dbref, "users")
        .then((data) => {
            datareturn = data.val()
        })
        .catch(() => {
            datareturn = false
        })
    return datareturn
}
