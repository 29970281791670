import CryptoJS from "crypto-js"
import { baseConnectDelete, baseConnectPut, baseConnectSearch} from "./firebase"
// Import the functions you need from the SDKs you need

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD17kpC15qPX4VNtXmeVSE5jaFkQ1mFexY",
  authDomain: "cardiosalusapp.firebaseapp.com",
  databaseURL: "https://cardiosalusapp-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "cardiosalusapp",
  storageBucket: "cardiosalusapp.appspot.com",
  messagingSenderId: "217301402264",
  appId: "1:217301402264:web:4f269ff2fc4714945f86ed"
};

// Initialize Firebase
initializeApp(firebaseConfig)


export async function firebaseAutenticate(where, query, objeto){
  let data = ""
  try {
    const response = await baseConnectSearch(where, query, objeto.email)
    if(response === null){
      const encriptPassword = CryptoJS.AES.encrypt(objeto.password, "cardiosalus").toString()
      objeto.password  = encriptPassword
      await baseConnectPut(`users/${objeto.index}`, objeto)
      data = true
    }else{
      data = false
    }
    return data
  } catch (error) {
    console.log(error)
    data = false
    return data
  }
}

export async function fireBaseSignIn(where, query, email, pass){
  let data = ""
  try {
    const response = await baseConnectSearch(where, query, email)
    if(response === null){
      data = false
    }else{
      const responseArray = Object.values(response)
      const bytes  = CryptoJS.AES.decrypt(responseArray[0].password, 'cardiosalus');
      const originalText = bytes.toString(CryptoJS.enc.Utf8);
      if(originalText === pass){
        data = responseArray[0]
      }else{
        data = false
      }
    }
    return data
  } catch (error) {
    console.log(error)
    data = false
  }
  return data
}

export async function resetPassword(index, password){
  let data = ""
  try {
    const encriptPassword = CryptoJS.AES.encrypt(password, "cardiosalus").toString()
    await baseConnectPut(`users/${index}/password`, encriptPassword)
    data = true
    return data
  } catch (error) {
    console.log(error)
    data = false
    return data
  }
}

export async function userDelete(index){
  let data = ""
  try {
    await baseConnectDelete(`users/${index}`)
    data = true
    return data
  } catch (error) {
    data = false
    return data
  }
}

export async function emailUpdate(index, email){
  let data = ""
  try {
    await baseConnectPut(`users/${index}/email`, email)
    data = true
    return data
  } catch (error) {
    data = false
    return data
  }
}